import React, { useState, useEffect } from "react"
import { Select, Typography, Row, Col, Avatar, Card } from "antd"
import moment from "moment"
import { useGetNewsQuery } from "../services/newsApi"
import { useGetCryptosQuery } from "../services/cryptoApi"
import Loader from "./Loader"
import { useIntl } from "react-intl";

const { Text, Title } = Typography
const { Option } = Select
const fallbackImage = "https://images.news18.com/ibnlive/uploads/2021/12/crypto.jpg"

function News(props) {
  const [newsCategory, setNewsCategory] = useState("Cryptocurrency")
  const { data: newsData } = useGetNewsQuery({ newsCategory, count: props.simplified ? 3 : 42 })
  const { data: cryptoData } = useGetCryptosQuery(100)
  const [news, setNews] = useState([])
  const [crypto, setCrypto] = useState([])
  const intl = useIntl();
  const selectCrypto = intl.formatMessage({ id: "message.select" });

  useEffect(() => {
    setNews(newsData?.value)
    setCrypto(cryptoData?.data)
  }, [newsData, cryptoData])

  if (!newsData?.value) return <Loader />

  return (
    <Row gutter={[24, 24]}>
      {!props.simplified && (
        <Col span={24}>
          <Select
            showSearch
            className="select-news"
            placeholder={selectCrypto}
            optionFilterProp="children"
            onChange={(value) => setNewsCategory(value)}
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            <Option value="Cryptocurrency">Cryptocurrency</Option>
            {crypto?.coins?.map(coin => (
              <Option value={coin.name}>{coin.name}</Option>
            ))}
          </Select>
        </Col>
      )}
      {news?.map((article, i) => (
        <Col xs={24} sm={12} lg={8} key={i}>
          <Card hoverable className="news-card">
            <a href={article.url} target="_blank" rel="noreferrer">
              <div className="news-image-container">
                <Title className="news-title" level={4}>{article.name}</Title>
                <img src={article?.image?.thumbnail?.contentUrl || fallbackImage} alt="" />
              </div>
              <p style={{ height: "90px", overflow: "hidden" }}>{article.description}...</p>
              <div className="provider-container">
                <div>
                  <Avatar src={article.provider[0]?.image?.thumbnail?.contentUrl || fallbackImage} alt="" />
                  <Text className="provider-name">{article.provider[0]?.name.substring(0, 25)}</Text>
                </div>
                <Text>{moment(article.datePublished).startOf("ss").fromNow()}</Text>
              </div>
            </a>
          </Card>
        </Col>
      ))}
    </Row>

  )
}

export default News