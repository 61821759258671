import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { Button, Menu, Typography, Avatar } from "antd"
import { HomeOutlined, MoneyCollectOutlined, BulbOutlined, FundOutlined, MenuOutlined, WhatsAppOutlined } from "@ant-design/icons"
import icon from "../images/cryptocurrency.svg"
import { FormattedMessage } from "react-intl";

function Navbar() {
    const [showMenu, setShowMenu] = useState(true)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    useEffect(() => {
        window.addEventListener("resize", () => { setWindowWidth(window.innerWidth) })

        return () => window.removeEventListener("resize", () => { setWindowWidth(window.innerWidth) })
    }, [])

    useEffect(() => {
        if (windowWidth > 800) {
            setShowMenu(true)
        } else {
            setShowMenu(false)
        }
    }, [windowWidth])

    return (
        <div className="nav-container">
            <div className="logo-container">
                <Avatar className="icon" src={icon} size="large" />
                <Typography.Title level={2} className="logo">
                    <Link to="/">Łódź OTC Desk</Link>
                </Typography.Title>
                <Button className="menu-control-container" onClick={() => setShowMenu(!showMenu)}>
                    <MenuOutlined />
                </Button>
            </div>
            {showMenu &&
                <Menu theme="dark">
                    <Menu.Item icon={<HomeOutlined />} key="0">
                        <Link to="/"><FormattedMessage id="message.menu-home" /></Link>
                    </Menu.Item>
                    <Menu.Item icon={<FundOutlined />} key="1">
                        <Link to="/cryptocurrencies"><FormattedMessage id="message.menu-crypto" /></Link>
                    </Menu.Item>
                    <Menu.Item icon={<MoneyCollectOutlined />} key="2">
                        <Link to="/exchanges"><FormattedMessage id="message.menu-exchange" /></Link>
                    </Menu.Item>
                    <Menu.Item icon={<BulbOutlined />} key="3">
                        <Link to="/news"><FormattedMessage id="message.menu-news" /></Link>
                    </Menu.Item>
                    <Menu.Item icon={<WhatsAppOutlined />} key="4">
                        <a href="https://wa.me/48123456789?text=I%27m%20interested%20in%20crypto" alt="buy-crypto" target="_blank" rel="noreferrer" ><FormattedMessage id="message.menu-buy" /></a>
                    </Menu.Item>
                </Menu>}
        </div>
    )
}
export default Navbar