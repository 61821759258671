import React from "react"
import PriceTag from "../images/one_btc.png"
import { FormattedMessage } from "react-intl";

function OTC(props) {

    return (
        <div className="price-container">
            <div className="price-card">
                <span className="price-desc"><FormattedMessage id="message.otc-buy" /></span>
                <span className="price-text">${(parseFloat(props.price) * 0.99).toFixed(2).toLocaleString("en")} <b>USD</b></span>
                <img src={PriceTag} alt="btc"></img>
            </div>
            <div className="price-card">
                <span className="price-desc"><FormattedMessage id="message.otc-sell" /></span>
                <span className="price-text">${(parseFloat(props.price) * 1.01).toFixed(2).toLocaleString("en")} <b>USD</b></span>
                <img src={PriceTag} alt="btc"></img>
            </div>
        </div>
    )
}

export default OTC