import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { Card, Row, Col, Input } from "antd"
import { UpSquareTwoTone, DownSquareTwoTone } from "@ant-design/icons"
import millify from "millify"
import { useGetCryptosQuery } from "../services/cryptoApi"
import Loader from "./Loader"
import { useIntl, FormattedMessage } from "react-intl";

function Cryptocurrencies(props) {
    const count = props.simplified ? 4 : 50
    const { data, isFetching } = useGetCryptosQuery(count)
    const [cryptos, setCryptos] = useState([])
    const [searchTerm, setSearchTerm] = useState("")
    const intl = useIntl();
    const searchCrypto = intl.formatMessage({ id: "message.crypto-search" });

    useEffect(() => {
        const filteredData = data?.data?.coins.filter(coin => coin.name.toLowerCase().includes(searchTerm.toLowerCase()))
        setCryptos(filteredData)
    }, [data, searchTerm])

    if (isFetching) return <Loader />

    return (
        <>
            {!props.simplified && (
                <div className="search-crypto">
                    <Input placeholder={searchCrypto} onChange={(e) => setSearchTerm(e.target.value)} />
                </div>
            )}
            <Row gutter={[8, 15]} className="crypto-card-container">
                {cryptos?.map(crypto => (
                    <Col xs={24} sm={12} lg={6} className="crypto-card" key={crypto.uuid}>
                        <Link to={`/crypto/${crypto.uuid}`}>
                            <Card
                                title={`${crypto.rank}. ${crypto.name}`}
                                extra={<img className="crypto-image" src={crypto.iconUrl} height="30px" alt="" />}
                                hoverable
                            >
                                <p><FormattedMessage id="message.crypto-price" />: ${parseFloat(crypto.price).toLocaleString("en")}</p>
                                <p><FormattedMessage id="message.crypto-cap" />: ${parseFloat(crypto.marketCap).toLocaleString("en")} ({millify(crypto.marketCap, { precision: 0 })})</p>
                                <p><FormattedMessage id="message.crypto-change" />: {crypto.change}% &nbsp;{crypto.change >= 0 ? <UpSquareTwoTone twoToneColor="#52c41a" /> : <DownSquareTwoTone twoToneColor="#eb2f96" />}</p>
                            </Card>
                        </Link>
                    </Col>
                ))}
            </Row>
        </>
    )
}

export default Cryptocurrencies