import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

const exchangeApiHeaders = {
    "X-RapidAPI-Host": "coinpaprika1.p.rapidapi.com",
    "X-RapidAPI-Key": "816c6acc12msh4a315ba5a39791cp15e6aejsn374a362042fc"
}

const baseUrl = "https://coinpaprika1.p.rapidapi.com/"

const createRequest = (url) => ({ url, headers: exchangeApiHeaders })

export const exchangeApi = createApi({
    reducerPath: "exchangeApi",
    baseQuery: fetchBaseQuery({ baseUrl }),
    endpoints: (builder) => ({
        getExchanges: builder.query({
            query: () => createRequest("/exchanges")
        }),
    })
})

export const {
    useGetExchangesQuery
} = exchangeApi;