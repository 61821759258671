import React from "react"
import { Typography, Row, Col, Statistic } from "antd"
import { Link } from "react-router-dom"
import { useGetCryptosQuery } from "../services/cryptoApi"
import { Cryptocurrencies, News, OTC } from "./"
import Loader from "./Loader"
import { FormattedMessage } from "react-intl";

const { Title } = Typography

function Homepage() {
    const { data, isFetching } = useGetCryptosQuery(10)
    const globalStats = data?.data

    if (isFetching) return <Loader />

    return (
        <>
            <Title level={2} className="heading"><FormattedMessage id="message.home-stats" /></Title>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 88 }}>
                <Col><Statistic title={<FormattedMessage id="message.stats-crypto" />} value={globalStats.stats.total} /></Col>
                <Col><Statistic title={<FormattedMessage id="message.stats-cap" />} value={globalStats.stats.totalMarketCap} prefix="$" /></Col>
                <Col><Statistic title={<FormattedMessage id="message.stats-volume" />} value={globalStats.stats.total24hVolume} prefix="$" /></Col>
                <Col><Statistic title={<FormattedMessage id="message.stats-exchange" />} value={globalStats.stats.totalExchanges} /></Col>
                <Col><Statistic title={<FormattedMessage id="message.stats-market" />} value={globalStats.stats.totalMarkets} /></Col>
            </Row>
            <div className="home-heading-container">
                <Title level={2} className="home-title"><FormattedMessage id="message.home-otc" /></Title>
                <Title level={3} className="show-more"><Link to="/crypto/Qwsogvtv82FCd"><FormattedMessage id="message.home-more" /></Link></Title>
            </div>
            <OTC price={globalStats.coins[0].price} />
            <div className="home-heading-container">
                <Title level={2} className="home-title"><FormattedMessage id="message.home-crypto" /></Title>
                <Title level={3} className="show-more"><Link to="/cryptocurrencies"><FormattedMessage id="message.home-more" /></Link></Title>
            </div>
            <Cryptocurrencies simplified={true} />
            <div className="home-heading-container">
                <Title level={2} className="home-title"><FormattedMessage id="message.home-news" /></Title>
                <Title level={3} className="show-more"><Link to="/news"><FormattedMessage id="message.home-more" /></Link></Title>
            </div>
            <News simplified={true} />
        </>
    )
}

export default Homepage