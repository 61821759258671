import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

const newsApiHeaders = {
    "X-BingApis-SDK": "true",
    "X-RapidAPI-Host": "bing-news-search1.p.rapidapi.com",
    "X-RapidAPI-Key": "816c6acc12msh4a315ba5a39791cp15e6aejsn374a362042fc"
}

const baseUrl = "https://bing-news-search1.p.rapidapi.com/news"

const createRequest = (url) => ({ url, headers: newsApiHeaders })

export const newsApi = createApi({
    reducerPath: "newsApi",
    baseQuery: fetchBaseQuery({ baseUrl }),
    endpoints: (builder) => ({
        getNews: builder.query({
            query: ({newsCategory, count}) => createRequest(`/search?q=${newsCategory}&safeSearch=Off&textFormat=Raw&freshness=Day&count=${count}`)
        })
    })
})

export const {
    useGetNewsQuery,
} = newsApi;