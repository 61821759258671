import React from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Col, Row, Typography } from "antd";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

const { Title } = Typography;

function HistoryChart(props) {
  const labels = [];
  const coinPrice = [];

  for (let i = 0; i < props.coinHistory?.history?.length; i += 1) {
    labels.unshift(new Date(props.coinHistory.history[i].timestamp * 1000).toLocaleDateString())
    coinPrice.unshift(props.coinHistory?.history[i].price)
  }

  const options = {
    responsive: true,
    scales: { y: { min: 0, } },
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Price History Chart",
      },
    },
    elements: {
      point: {
        borderWidth: 10,
        radius: 0,
        backgroundColor: "rgba(0,0,0,0)"
      }
    }
  };

  const data = {
    labels,
    datasets: [
      {
        label: "Price in USD",
        data: coinPrice,
        backgroundColor: "#0071bd",
        borderColor: "#0071bd",
      },
    ],
  }

  return (
    <>
      <Row className="chart-header">
        <Title level={2} className="chart-title">{props.coinName} Price Chart </Title>
        <Col className="price-container">
          <Title level={5} className="price-change">Change: {props.coinHistory?.change}%</Title>
          <Title level={5} className="current-price">Current {props.coinName} Price: $ {props.currentPrice}</Title>
        </Col>
      </Row>

      <Line options={options} data={data} />

    </>
  );
};

export default HistoryChart;