import React, { useState, useEffect } from "react"
import { Routes, Route, Link } from "react-router-dom"
import { Layout, Typography, Space } from "antd"
import { CopyrightOutlined } from "@ant-design/icons"
import { Navbar, Homepage, Exchanges, Cryptocurrencies, CryptoDetails, News } from "./components"
import WhatsApp from "./images/whatsapp.png"
import "./App.css"
import { useIntl, IntlProvider, FormattedMessage } from "react-intl";

let initLocale = "en";
if (navigator.language === "pl-PL") {
    initLocale = "pl-PL";
}

function loadMessages(locale) {
    switch (locale) {
        case "en":
            return import("./lang/en.json");
        case "pl-PL":
            return import("./lang/pl-PL.json");
        default:
            return import("./lang/en.json");
    }
}

function getDirection(locale) {
    switch (locale) {
        case "en":
            return "ltr";
        case "pl-PL":
            return "ltr";
        default:
            return "ltr";
    }
}

function LocalizationWrapper() {
    const [locale, setLocale] = useState(initLocale);
    const [messages, setMessages] = useState(null);

    useEffect(() => loadMessages(locale).then(setMessages), [locale]);

    return messages ? (
        <IntlProvider locale={locale} messages={messages}>
            <App locale={locale} direction={getDirection(locale)} onLocaleChange={(locale) => setLocale(locale)} />
        </IntlProvider>
    ) : null;
}
export default LocalizationWrapper;

function App({ locale, direction, onLocaleChange }) {
    const intl = useIntl();
    const whatsAppLink = intl.formatMessage({ id: "message.whatsapp-link" });

    return (
        <div dir={direction} className="app">
            <div className="navbar">
                <Navbar />
            </div>
            <div className="main">
                <div className="header">
                    <select value={locale} onChange={(e) => onLocaleChange(e.target.value)}>
                        <option value="en">English</option>
                        <option value="pl-PL">Polski</option>
                    </select>
                </div>
                <Layout>
                    <div className="routes">
                        <Routes>
                            <Route exact path="/" element={<Homepage />} />
                            <Route exact path="/exchanges" element={<Exchanges />} />
                            <Route exact path="/cryptocurrencies" element={<Cryptocurrencies />} />
                            <Route exact path="/crypto/:coinId" element={<CryptoDetails />} />
                            <Route exact path="/news" element={<News />} />
                        </Routes>
                    </div>
                </Layout>
                <div className="footer" level={5}>
                    <Typography.Title level={5} style={{ color: "white", textAlign: "center" }}>
                        <FormattedMessage id="message.footer-address" /> &nbsp;
                        <CopyrightOutlined /> {new Date().getFullYear()}
                    </Typography.Title>
                    <Space>
                        <a href="https://wa.me/48123456789?text=I%27m%20interested%20in%20crypto" alt="buy-crypto" target="_blank" rel="noreferrer" ><FormattedMessage id="message.menu-buy" /></a>
                        <Link to="/"><FormattedMessage id="message.menu-home" /></Link>
                        <Link to="/cryptocurrencies"><FormattedMessage id="message.menu-crypto" /></Link>
                        <Link to="/exchanges"><FormattedMessage id="message.menu-exchange" /></Link>
                        <Link to="/news"><FormattedMessage id="message.menu-news" /></Link>
                    </Space>
                </div>
            </div>
            <a href={whatsAppLink} alt="buy-crypto" target="_blank" rel="noreferrer">
                <img className="contact" src={WhatsApp} alt="whatsapp" />
            </a>
        </div>
    )
}
