import React, { useState, useEffect } from "react"
import { Card, Row, Col, Typography } from "antd"
import millify from "millify"
import { useGetExchangesQuery } from "../services/exchangeApi"
import Loader from "./Loader"
import { FormattedMessage } from "react-intl";

const { Text, Paragraph } = Typography;

function Exchanges() {
    const { data } = useGetExchangesQuery()
    const [exchanges, setExchanges] = useState()

    const gridStyle = {
        width: '25%',
        textAlign: 'center',
        padding: "24px 0px",
    };

    const gridStyleLong = {
        width: '100%',
        textAlign: 'left',
        padding: "10px 24px 0px 24px"
    };

    useEffect(() => {
        let rankedExchanges = data?.filter(exchange => exchange.adjusted_rank != null)
        rankedExchanges?.sort((a, b) => a.adjusted_rank - b.adjusted_rank)
        let slicedExchanges = rankedExchanges?.slice(0, 24)
        setExchanges(slicedExchanges)
    }, [data])

    if (!exchanges) return <Loader />

    function getTimeZone() {
        var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
        return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
    }

    return (
        <>
            <Text italic className="last-update">
            <FormattedMessage id="message.last-update" />{`: ${new Date(exchanges[0].last_updated).toLocaleString("en")} (GMT${getTimeZone()})`}
            </Text>
            <Row gutter={[8, 15]}>
                {exchanges.map((exchange) => (
                    <Col span={24} key={exchange.id}>
                        <Card
                            title={exchange.name}
                            extra={
                                <div>
                                    <a href={exchange.links.website[0]} target="_blank" rel="noreferrer">
                                        <img className="exchange-logo" src={`http://www.google.com/s2/favicons?domain=${exchange.links.website[0]}`} height="16px" alt="" />
                                        <FormattedMessage id="message.exchange-website" />
                                    </a>
                                </div>
                            }
                            hoverable
                        >
                            <Card.Grid style={gridStyle} hoverable={false}><Text><strong>Volume 24h</strong></Text></Card.Grid>
                            <Card.Grid style={gridStyle} hoverable={false}><Text><strong>Volume 30d</strong></Text></Card.Grid>
                            <Card.Grid style={gridStyle} hoverable={false}><Text><strong>Markets</strong></Text></Card.Grid>
                            <Card.Grid style={gridStyle} hoverable={false}><Text><strong>Currencies</strong></Text></Card.Grid>
                            <Card.Grid style={gridStyle} hoverable={false}>${millify(exchange.quotes.USD.adjusted_volume_24h)}</Card.Grid>
                            <Card.Grid style={gridStyle} hoverable={false}>${millify(exchange.quotes.USD.adjusted_volume_30d)}</Card.Grid>
                            <Card.Grid style={gridStyle} hoverable={false}>{exchange.markets}</Card.Grid>
                            <Card.Grid style={gridStyle} hoverable={false}>{exchange.currencies}</Card.Grid>
                            {exchange.description &&
                                <Card.Grid style={gridStyleLong} hoverable={false}>
                                    <Paragraph
                                        ellipsis={{
                                            rows: 2,
                                            expandable: true,
                                        }}
                                    >
                                        {exchange.description}
                                    </Paragraph>
                                </Card.Grid>
                            }
                        </Card>
                    </Col>
                ))}
            </Row>
        </>
    )
}

export default Exchanges