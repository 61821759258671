import React, { useState, useEffect } from "react"

import { useParams } from "react-router-dom"
import HTMLReactParser from "html-react-parser"
import { Col, Row, Typography, Select } from "antd"
import { MoneyCollectOutlined, DollarCircleOutlined, FundOutlined, ExclamationCircleOutlined, StopOutlined, TrophyOutlined, CheckOutlined, NumberOutlined, ThunderboltOutlined } from "@ant-design/icons"
import millify from "millify"

import { useGetCryptoDetailsQuery, useGetCryptoHistoryQuery } from "../services/cryptoApi"
import HistoryChart from "./HistoryChart"
import Loader from "./Loader"

const { Text, Title } = Typography
const { Option } = Select

function CryptoDetails() {
    const [details, setDetails] = useState()
    const [history, setHistory] = useState([])
    const [timePeriod, setTimePeriod] = useState("1y")
    const { coinId } = useParams()
    const { data: detailsData } = useGetCryptoDetailsQuery(coinId)
    const { data: historyData } = useGetCryptoHistoryQuery({coinId, timePeriod})

    useEffect(() => {
        setDetails(detailsData?.data?.coin)
        setHistory(historyData?.data)
    }, [detailsData, historyData])

    if (!details) return <Loader />

    const time = ["3h", "24h", "7d", "30d", "1y", "3m", "3y", "5y"];

    const stats = [
        { title: "Price to USD", value: `$ ${details?.price && millify(details?.price)}`, icon: <DollarCircleOutlined /> },
        { title: "Rank", value: details?.rank, icon: <NumberOutlined /> },
        { title: "24h Volume", value: `$ ${details?.["24hVolume"] && millify(details?.["24hVolume"])}`, icon: <ThunderboltOutlined /> },
        { title: "Market Cap", value: `$ ${details?.marketCap && millify(details?.marketCap)}`, icon: <DollarCircleOutlined /> },
        { title: "All-time-high(daily avg.)", value: `$ ${details?.allTimeHigh?.price && millify(details?.allTimeHigh?.price)}`, icon: <TrophyOutlined /> },
    ];

    const genericStats = [
        { title: "Number Of Markets", value: details?.numberOfMarkets, icon: <FundOutlined /> },
        { title: "Number Of Exchanges", value: details?.numberOfExchanges, icon: <MoneyCollectOutlined /> },
        { title: "Aprroved Supply", value: details?.supply?.confirmed ? <CheckOutlined /> : <StopOutlined />, icon: <ExclamationCircleOutlined /> },
        { title: "Total Supply", value: `$ ${details?.supply?.total && millify(details?.supply?.total)}`, icon: <ExclamationCircleOutlined /> },
        { title: "Circulating Supply", value: `$ ${details?.supply?.circulating && millify(details?.supply?.circulating)}`, icon: <ExclamationCircleOutlined /> },
    ];

    return (
        <Col className="coin-details-container">
            <Col className="coin-heading-container">
                <Title level={2}>{details.name} ({details.symbol}) Price</Title>
                <p>
                    {details.name} live price in US dollars.
                    View value statistics, market cap and supply.
                </p>
            </Col>
            <Select defaultValue="1y" className="select-timeperiod" placeholder="Select Time Period" onChange={(value) => setTimePeriod(value)}>
                {time.map((date) => <Option key={date}>{date}</Option>)}
            </Select>
            <HistoryChart coinHistory={history} currentPrice={millify(details.price)} coinName={details.name} />
            <Col className="stats-container">
                <Col className="coin-value-statistics">
                    <Col className="coin-value-statistics-heading">
                        <Title level={3} className="coin-details-heading">{details.name} Value Statistics</Title>
                        <p>An overview showing the statistics of {details.name}, such as the base and quote currency, the rank, and trading volume.</p>
                    </Col>
                    {stats.map(({ icon, title, value }) => (
                        <Col className="coin-stats" key={title}>
                            <Col className="coin-stats-name">
                                <Text>{icon}</Text>
                                <Text>{title}</Text>
                            </Col>
                            <Text className="stats">{value}</Text>
                        </Col>
                    ))}
                </Col>
                <Col className="other-stats-info">
                    <Col className="coin-value-statistics-heading">
                        <Title level={3} className="coin-details-heading">Other Stats Info</Title>
                        <p>An overview of the number of markets, exchanges, approved supply, total supply, and circulating supply.</p>
                    </Col>
                    {genericStats.map(({ icon, title, value }) => (
                        <Col className="coin-stats" key={title}>
                            <Col className="coin-stats-name">
                                <Text>{icon}</Text>
                                <Text>{title}</Text>
                            </Col>
                            <Text className="stats">{value}</Text>
                        </Col>
                    ))}
                </Col>
            </Col>
            <Col className="coin-desc-link">
                <Row>
                    <Title level={3} className="coin-details-heading">
                        What is {details.name}?
                    </Title>
                    {HTMLReactParser(`${details.description}`)}
                </Row>
                <Col className="coin-links">
                    <Title level={3} className="coin-details-heading">
                        Official Link
                    </Title>
                    <Row className="coin-link">
                        {<a href={details.websiteUrl}>{details.websiteUrl.toString()}</a>}
                    </Row>
                    <Title level={3} className="coin-details-heading">
                        More Links
                    </Title>
                    {details.links.map(link => (
                        <Row className="coin-link" key={link.url}>
                            <a href={link.url}> {link.name}</a>
                        </Row>
                    ))}
                </Col>
            </Col>
        </Col>
    )
}

export default CryptoDetails